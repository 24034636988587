import { useCallback, useEffect, useState } from 'react'
import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { isEmptyOrUndefined, message } from '../helpers/helpers';
import tableStyles from '../components/Layouts/tableStyle.module.scss'
/* --------------------------- How to use example --------------------------- */
// const { results: chemicalSubstances, load: chemicalSubsLoader, trigger: getChemicalSubs } = useGetMethod()
// results : object 'results' sended in the response 
// load : boolean that indique the loader state
// trigger: method that incharger the execute the request
// useEffect(async () => {
//     getChemicalSubs({
//         url: '/medical/medicine/',
//         objFilters: { idAccount: idEnterprise },
//         token: token,
//     })
//     // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [])
export const useGetMethod = () => {
    const [results, setResults] = useState({})
    const [load, setLoad] = useState(false)

    const fetchGet = async (configJson) => {
        let { url, objFilters, array, token, doAfterSuccess, succesAction, doAfterException } = configJson;
        const filters = !!array ? array : convertFilterToString(objFilters);
        if (isEmptyOrUndefined(url)) {
            setLoad(false)
            return message(
                "warning",
                "Parámetro url no encontrado",
                "El parámetro url es obligatorio en el objeto de configuración"
            );
        }
        if (isEmptyOrUndefined(token)) {
            setLoad(false)
            return message(
                "warning",
                "Parámetro token no encontrado",
                "El parámetro token es obligatorio en el objeto de configuración"
            );
        }
        setLoad(true)
        try {
            const query = await fetch(`${URL_GATEWAY}${API_VERSION}${url}?${filters}`, {
                method: "GET",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            });
            let results = await query.json();
            // console.log(results, 'results')
            if (results.success || results?.data?.length || results?.results?.length) {
                setLoad(false);
                setResults(results);
                !!doAfterSuccess && doAfterSuccess(results);
                !!succesAction && succesAction(results);
                return results;
            } else {
                setLoad(false);
                if (doAfterException) {
                    doAfterException(results);
                } else {
                    console.log('warning', results.title || "Advertencia", results.message);
                }
                return results;
            }
        } catch (error) {
            setLoad(false)
            console.error(error.message);
            return message("error", "Error", error?.message ? error?.message : "Ha ocurrido un error");
        }
    };
    const trigger = useCallback(
        (configJson) => {
            return fetchGet(configJson)
        },
        [])
    return (
        { load, results, trigger }
    )
}
/* ---------------------------------- POST ---------------------------------- */
// const { load: justCreateLoader, trigger: justificationSubmit } = usePostMethod()
// justificationSubmit({
//     url: "/medical/justification",
//     token: token,
//     doAfterSuccess: () => {
//         getMedicalJustResults({
//             url: "/medical/justification",
//             objFilters: { idAccount: idEnterprise, search: filters.search },
//             token: token,
//         })

//         setJustification({
//             isEditing: false,
//             isDeleting: false,
//             showModal: false,
//             pbjDescription: "",
//             pbjName: "",
//             pbjId: "",
//             selectedMedicines: [],
//             selectedJustifications: []
//         })
//     },
//     successAlert: () => message('success', "Éxito", `Justificación PBS ${justification.isEditing ? 'actualizada' : 'creada'}`),
//     method: justification.isEditing ? "PUT" : "POST",

//     body: {
//         pbjName: justification.pbjName,
//         pbjDescription: justification.pbjDescription,
//         medicines: justification.isEditing ? justification.arrIdMed : justification.selectedMedicines,
//         diagnostics: justification.isEditing ? justification.arrIdDiag : justification.selectedJustifications,
//         idAccount: idEnterprise,
//         pbjId: justification.pbjId || ""
//     },
// })
export const usePostMethod = () => {
    const [results, setResults] = useState({})
    const [load, setLoad] = useState(false)
    useEffect(() => {
        setResults({})
    }, [])

    const fetchPost = async (fetchProps) => {
        let { url, token, method, body, succesAction, doAfterException, doAfterSuccess, noAlert = false, noErrorAlert = false } = fetchProps;
        if (isEmptyOrUndefined(url)) {
            setLoad(false)
            return message(
                "warning",
                "Parámetro url no encontrado",
                "El parámetro url es obligatorio en el objeto de configuración");
        }
        if (isEmptyOrUndefined(token)) {
            setLoad(false)
            return message(
                "warning",
                "Parámetro token no encontrado",
                "El parámetro token es obligatorio en el objeto de configuración");
        }
        setLoad(true)
        try {
            const query = await fetch(`${URL_GATEWAY}${API_VERSION}${url}`, {
                method: method,
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(fetchProps.body ? fetchProps.body : body),
            });
            let results = await query.json();
            if (results.success || results.status === 200) {
                setLoad(false)
                setResults(results)
                !!doAfterSuccess && doAfterSuccess(results)
                return !!succesAction ? succesAction(results) : !noAlert && message('success', `<span class=${tableStyles.ordClearBlueText} > ${results.title || 'Proceso completado'} </span>`, results.message)
            } else {
                setLoad(false)
                return !!doAfterException ? doAfterException(results) : !noErrorAlert && message('warning', results.title || "Advertencia", results.message)
            }
        } catch (error) {
            setLoad(false)
            console.log("error: ", error);
            return message("error", "Error", "Ha ocurrido un error POST");
        }
    };

    const trigger = useCallback(
        (fetchProps) => {
            fetchPost(fetchProps)
        },
        [],
    )
    return (
        { load, results, trigger })
}

/* -------------------------------- MULTI GET ------------------------------- */
// getUnities({
//     urls :  [
//     {
//         url: "url1",
//         objFilters: { search: "hola" }
//         doAfterSuccess: () => setUnitList({ ...unitList, recipeUnitList: unitiesResult })
//     },
//     {
//         url: "url2",
//         objFilters: { search: "hola" }
//         doAfterSuccess: () => setUnitList({ ...unitList, recipeUnitList: unitiesResult })
//     },
//     {
//         url: "url3",
//         objFilters: { search: "hola" }
//         doAfterSuccess: () => setUnitList({ ...unitList, recipeUnitList: unitiesResult })
//     },
// ]
//     token: token,
// })

export const useMultiGetMethod = () => {
    const [results, setResults] = useState({})
    const [load, setLoad] = useState(false)

    const fetchMultiGet = (configJson) => {
        let { multipleGet, token } = configJson;
        if (multipleGet.length <= 0) {
            setLoad(false)
            return message(
                "warning",
                "Parámetro multipleGet no encontrado",
                "El parámetro url es obligatorio en el objeto de configuración"
            );
        }
        if (isEmptyOrUndefined(token)) {
            setLoad(false)
            return message(
                "warning",
                "Parámetro token no encontrado",
                "El parámetro token es obligatorio en el objeto de configuración"
            );
        }

        let uniqueResult = {}
        multipleGet?.forEach((e, i) => {
            const uniqueGet = async (e) => {
                setLoad(true)
                try {
                    const filters = convertFilterToString(e.objFilters);
                    const query = await fetch(`${URL_GATEWAY}${API_VERSION}${e.url}?${filters}`, {
                        method: "GET",
                        headers: {
                            Authorization: token,
                            "Content-Type": "application/json",
                        },
                    });
                    let results = await query.json();

                    if (e?.requestName) {
                        uniqueResult[e?.requestName] = results
                    } else {
                        uniqueResult[e?.url + `  -#${i}`] = results
                    }
                    !!e?.doAfterSuccess && e?.doAfterSuccess(uniqueResult[e?.requestName])
                    setLoad(false)
                } catch (error) {
                    setLoad(false)
                    console.error(error.message);
                    return message("error", "Error", "Ha ocurrido un error");
                }
            }
            uniqueGet(e)
        });
        setResults(uniqueResult)
        return uniqueResult
    };
    // const trigger = (configJson) => {
    //     fetchMultiGet(configJson)
    // }
    const trigger = useCallback(
        (configJson) => {
            return fetchMultiGet(configJson)
        },
        [])
    return (
        { load, results, trigger }
    )
}
/* ------------------------------- Example use ------------------------------ */
//  const { results: multiGetTestResult, load: multiGetLoader, trigger: multiGetTest } = useMultiGetMethod()
//  multiGetTest({
//     multipleGet: [
//         {
//             url: "/medical/medicine/getPharmaUnit/",
//             objFilters: { type: "Unidad de receta" },
//             requestName: "Request1",
//             doAfterSuccess: () => //console.log('se hizo la petición #1')
//         },
//         {
//             url: "/medical/medicine/getPharmaUnit/",
//             objFilters: { type: "Presentación" },
//             // requestName: "Request2",
//             doAfterSuccess: () => //console.log('se hizo la petición #2')
//         },
//         {
//             url: "/medical/medicine/getPharmaUnit/",
//             objFilters: { type: "Unidad de receta" },
//             requestName: "Request3",
//             doAfterSuccess: () => //console.log('se hizo la petición #3')
//         },
//     ],
//     token: token,
// })

//1: Importar el método requerido
// import { useMultiGetMethod } from '../Hooks/useFetch';
// import { loader } from '../helpers/helpers';
//2: Extraer los estados results, load y trigger del método useMultiGetMethod, se les asigna un alias a cada estado para evitar errores de variables duplicadas
//results: devuelve la respuesta del backend, dicha respuesta será almacenada en la variable “unities”
//load: Devuelve un dato booleano que será true mientras se ejecute la petición y cambiará a false cuando se obtenga una respuesta o error
// trigger: es la función disparadora del llamado la api, este parámetro recibirá un listado (la llave debe ser "multipleGet") de objetos de configuración indicando los parámetros de cada fetch, en este caso le llamaremos getUnities , también recibirá el token como segundo parametro
// const { results: unities, load: unitiesLoader, trigger: getUnities } = useMultiGetMethod()
//EJECUTANDO LA PETICIÓN
//Nos valdremos de  un useEffect como disparador del multiGet
// useEffect(() => {
// getUnities({
//es imperativo que el array de objetos tenga por nombre multipleGet
//         multipleGet: [
//             {
//                 url: "/medical/medicine/getPharmaUnit/",//Url pura de la petición sin parametros
//                 objFilters: { type: "Unidad de Medida" },// parametros de filtrado o paginación
//                 requestName: "recipeUnitList",// nombre de la llave en la que quedará almacenado el resultado de esta petición
//                 doAfterSuccess: (res) => { setCompaniesToRender(res?.results) } //Lo que quieres que haga luego de que la petición salga bien, el parametro res tambien lleva la respuesta del backend por si la necesitas 🧐
//             },
//             {
//                 url: "/medical/medicine/getPharmaUnit/",
//                 objFilters: { type: "Presentación" },
//                 requestName: "presentationList",
//             },
//         ],
//         token: token, // String el token del usuario en sesión, se obtiene del loginReducer.Authorization
//     })
// }, [])
// Renderizando loading
//Siempre y cuando unitiesLoader sea verdadero se mostrará en loader en pantalla
//{unitiesLoader && loader}
// let pharmaUnit = unities.recipeUnitList // alias del result.requestName
// let pharmaPresentation = unities.presentationList// alias del result.requestName
